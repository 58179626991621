import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { provideRouter } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { EventsComponent } from './components/events/events.component';
import { EventFormComponent } from './components/event-form/event-form.component';
import { EventComponent } from './components/event/event.component';
import { ErrorComponent } from './components/error/error.component';
import { ShoutoutCreateComponent } from './components/shoutout-create/shoutout-create.component';
import { ShoutoutConfirmedComponent } from './components/shoutout-confirmed/shoutout-confirmed.component';
import { AuthGuard } from './guards/auth.guard';
import { ShoutoutsComponent } from './components/shoutouts/shoutouts.component';
import { EventWallComponent } from './components/event-wall/event-wall.component';
import { EventQrComponent } from './components/event-qr/event-qr.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { AuthService } from './services/auth.service';
import { UserResolverService } from './services/user-resolver.service';
import { EventResolverService } from './services/event-resolver.service';
import { ShoutoutsResolverService } from './services/shoutouts-resolver.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', title: 'Home' },
  { path: 'login', component: LoginComponent, title: 'Log In' },
  { path: 'signup', component: SignupComponent, title: 'Sign Up' },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    title: 'Forgot Password',
  },
  {
    path: 'events',
    title: 'Events',
    component: EventsComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['admin', 'organizer', 'announcer'],
    },
    resolve: {
      user: UserResolverService,
    },
  },
  {
    path: 'events/create',
    title: 'Create Event',
    component: EventFormComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['admin', 'organizer'],
    },
    resolve: {
      user: UserResolverService,
    },
  },
  {
    path: 'events/:eventId/edit',
    title: 'Edit Event',
    component: EventFormComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['admin', 'organizer'],
    },
    resolve: {
      user: UserResolverService,
      event: EventResolverService,
    },
  },
  {
    path: 'events/:eventId/wall',
    title: 'Event Wall',
    component: EventWallComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['admin', 'organizer', 'announcer'],
    },
    resolve: {
      event: EventResolverService,
    },
  },
  {
    path: 'events/:eventId/qr',
    title: 'Event QR Code',
    component: EventQrComponent,
  },
  {
    path: 'events/:eventId',
    title: 'Event',
    component: EventComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['admin', 'organizer', 'announcer'],
    },
    resolve: {
      user: UserResolverService,
      event: EventResolverService,
    },
    children: [
      {
        path: '',
        redirectTo: 'shoutouts/announce',
        pathMatch: 'full',
        title: 'Shoutouts',
      },
      {
        path: 'shoutouts/announce',
        title: 'Announce Shoutout',
        component: ShoutoutsComponent,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: ['admin', 'organizer', 'announcer'],
          paid: true,
          moderated: false,
          approved: false,
          announced: false,
        },
      },
      {
        path: 'shoutouts/completed',
        title: 'Completed Shoutouts',
        component: ShoutoutsComponent,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: ['admin', 'organizer', 'announcer'],
          paid: true,
          moderated: true,
          approved: true,
          announced: true,
        },
        resolve: {
          shoutouts: ShoutoutsResolverService,
        },
      },
      {
        path: 'shoutouts/rejected',
        title: 'Rejected Shoutouts',
        component: ShoutoutsComponent,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: ['admin', 'organizer', 'announcer'],
          paid: true,
          moderated: true,
          approved: false,
          announced: false,
        },
        resolve: {
          shoutouts: ShoutoutsResolverService,
        },
      },
    ],
  },
  {
    path: 'events/:eventId/shoutouts/create',
    title: 'Create Shoutout',
    component: ShoutoutCreateComponent,
    resolve: {
      event: EventResolverService,
    },
    data: {
      allowedRoles: [],
    },
  },
  {
    path: 'events/:eventId/shoutouts/confirmed',
    title: 'Shoutout Confirmed',
    component: ShoutoutConfirmedComponent,
  },

  { path: 'error/:code', component: ErrorComponent, title: 'Error' },
  { path: '**', component: ErrorComponent, title: 'Error' },
];

@NgModule({
  providers: [provideRouter(routes)],
})
export class AppRoutingModule {
  constructor(private auth: AuthService) {}
}
