<mat-toolbar color="primary">
  <span class="header-title"
    ><a href="https://ultimateshoutouts.com"
      ><img src="/assets/logo.png" class="logo" /></a
  ></span>
  <span class="header-spacer"></span>
  <div *ngIf="!hideMenu">
    <div *ngIf="auth.user$ | async">
      <div *ngIf="!isMobile">
        <button mat-button aria-label="Events" routerLink="/events">
          <mat-icon>event</mat-icon>Events
        </button>
        <button mat-button aria-label="Logout" (click)="logout()">
          <mat-icon>logout</mat-icon>Logout
        </button>
      </div>
      <div *ngIf="isMobile">
        <button
          mat-icon-button
          class="header-icon"
          aria-label="Header Menu"
          [matMenuTriggerFor]="mainMenu"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #mainMenu="matMenu">
          <button mat-menu-item routerLink="/events">
            <mat-icon>event</mat-icon>Events
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>Logout
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
