<div class="qr">
  <button
    class="print-btn"
    mat-flat-button
    color="primary"
    (click)="printQRCode()"
  >
    Print QR code
  </button>
  <h1 class="qr-header">Shoutouts for your Favorite Athlete</h1>
  <div class="qr-footer" *ngIf="event$ | async as event">
    <h2 class="qr-footer__event">{{ event.name }}</h2>
    <h3 class="qr-footer__event">{{ event.clientName }}</h3>
  </div>
  <qrcode [qrdata]="shareLink()" [width]="qrWidth" [errorCorrectionLevel]="'M'">
  </qrcode>
  <h1 class="qr-footer__price" *ngIf="event$ | async as event">
    Price: ${{ event?.pricing?.toFixed(2) }}
  </h1>
  <div class="logo"><img src="/assets/logo.png" class="logo" /></div>
  <a mat-button href="ultimateshoutouts.com" class="branding"
    >ultimateshoutouts.com</a
  >
</div>
