import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Event } from 'src/app/models/event.model';
import { Session } from 'src/app/models/session.model';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormComponent {
  eventForm: UntypedFormGroup;
  eventsCollection: AngularFirestoreCollection<Event>;
  event!: Event;
  user!: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder,
  ) {
    this.route.data.subscribe(({ event, user }) => {
      this.user = user;
      this.event =
        event ||
        ({
          name: '',
          clientName: '',
          organizerIds: [this.user.uid],
          announcerIds: [],
          streetAddress: '',
          city: '',
          state: '',
          country: '',
          phoneNumber: '',
          description: '',
          startTime: new Date(),
          endTime: new Date(),
          pricing: 5,
          sessions: [] as Session[],
        } as Event);
    });
    this.eventsCollection = this.afs.collection<Event>('events');

    this.eventForm = this.fb.group({
      id: this.event.id,
      name: [
        this.event?.name,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
        ],
      ],
      organizerIds: this.fb.array(this.event.organizerIds),
      announcerIds: this.fb.array(this.event.announcerIds),
      clientName: [
        this.event?.clientName,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
        ],
      ],
      streetAddress: [
        this.event?.streetAddress,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
        ],
      ],
      city: [
        this.event?.city,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      state: [
        this.event?.state,
        [Validators.required, Validators.minLength(2), Validators.maxLength(2)],
      ],
      country: [
        this.event?.country,
        [Validators.minLength(3), Validators.maxLength(3)],
      ],
      phoneNumber: [
        this.event?.phoneNumber,
        [Validators.minLength(10), Validators.maxLength(15)],
      ],
      description: [
        this.event?.description,
        [Validators.minLength(10), Validators.maxLength(255)],
      ],
      startTime: [new Date(this.event?.startTime), [Validators.required]],
      endTime: [new Date(this.event?.endTime), [Validators.required]],
      pricing: [
        this.event?.pricing,
        [Validators.required, Validators.min(2), Validators.max(100)],
      ],
      sessions: this.event?.sessions
        ? this.fb.array(this.event?.sessions.map((s) => this.fb.group(s)))
        : this.fb.array([]),
    });
  }

  get name() {
    return this.eventForm.get('name');
  }
  get clientName() {
    return this.eventForm.get('clientName');
  }
  get streetAddress() {
    return this.eventForm.get('streetAddress');
  }
  get city() {
    return this.eventForm.get('city');
  }
  get state() {
    return this.eventForm.get('state');
  }
  get country() {
    return this.eventForm.get('country');
  }
  get phoneNumber() {
    return this.eventForm.get('phoneNumber');
  }
  get description() {
    return this.eventForm.get('description');
  }
  get startTime() {
    return this.eventForm.get('startTime');
  }
  get endTime() {
    return this.eventForm.get('endTime');
  }
  get pricing() {
    return this.eventForm.get('pricing');
  }
  get sessions() {
    return this.eventForm.get('sessions') as FormArray;
  }

  addSession() {
    this.sessions.push(
      this.fb.group({
        id: new Date().getTime().toString(),
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(255),
          ],
        ],
      }),
    );
  }

  removeSession(index: number) {
    (this.sessions as FormArray).removeAt(index);
  }

  convertFormToEvent(form: UntypedFormGroup): Event {
    const event = form.value as Event;
    event.startTime = form.value.startTime.toISOString();
    event.endTime = form.value.endTime.toISOString();
    return event;
  }

  onSubmit = async (shouldNavigate: boolean, stepper: CdkStepper) => {
    if (this.event.id && this.auth.canEditEvents(this.user, this.event)) {
      await this.afs
        .doc(`events/${this.event.id}`)
        .update(this.convertFormToEvent(this.eventForm));
    } else if (!this.event.id && this.auth.canCreateEvents(this.user)) {
      const event = await this.eventsCollection.add(
        this.convertFormToEvent(this.eventForm),
      );
      this.event.id = event.id;
    } else {
      console.error('Access denied. Must have permission to edit content');
      this.router.navigate(['/error', 403]);
      return;
    }
    if (shouldNavigate) {
      this.router.navigate(['events', this.event.id]);
    } else {
      stepper.next();
    }
  };
}
