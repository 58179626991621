import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable, take } from 'rxjs';
import { shareShoutout } from 'src/app/helpers/shareShoutoutLink';

@Component({
  selector: 'app-shoutout-confirmed',
  templateUrl: './shoutout-confirmed.component.html',
  styleUrls: ['./shoutout-confirmed.component.scss']
})
export class ShoutoutConfirmedComponent implements OnInit {
  eventId!: string | null;
  event$!: Observable<Event | undefined>;
  orderId!: string | null;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.eventId = params.get('eventId');
      this.event$ = this.afs
        .doc<Event>('events/' + this.eventId)
        .valueChanges({ idField: 'id' });
    });
    this.route.queryParamMap.subscribe(params => {
      this.orderId = params.get('orderId');
    });
  }

  share(): void {
    this.event$.pipe(take(1)).subscribe((event) => shareShoutout(event));
  }

}
