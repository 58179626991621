<div class="event-header" *ngIf="event">
  <h1>{{ event.name }}</h1>
  <span class="event-header__spacer"></span>
  <span class="event-header__item"
    ><a mat-button color="secondary" [routerLink]="['wall']" target="_blank"
      ><mat-icon
        aria-hidden="false"
        aria-label="Display Shoutout Wall"
        matTooltip="Display Shoutout Wall"
        fontIcon="tv"
      ></mat-icon
    ></a>
    <a mat-button color="secondary" [matMenuTriggerFor]="shoutoutStats"
      ><mat-icon
        aria-hidden="false"
        aria-label="Shoutout Count"
        matTooltip="Shoutout Count"
        fontIcon="show_chart"
      ></mat-icon
    ></a>
    <mat-menu #shoutoutStats="matMenu">
      <div class="event-stats">
        <h2>Event Stats</h2>
        <h3 class="event-stats__counters">
          <span class="event-stats__counter"
            ><span class="event-stats__label">Shoutouts Sent: </span
            >{{ (eventCounter$ | async)?.shoutoutCount || 0 }}</span
          >
          <span class="event-stats__counter"
            ><span class="event-stats__label">Gross Revenue: </span>${{
              ((eventCounter$ | async)?.grossRevenue || 0).toFixed(2)
            }}</span
          >
          <span class="event-stats__counter"
            ><span class="event-stats__label">Net Revenue: </span
            ><span class="event-stats__value--highlight"
              >${{
                ((eventCounter$ | async)?.netRevenue || 0).toFixed(2)
              }}</span
            ></span
          >
        </h3>
      </div>
    </mat-menu>
    <a mat-button color="secondary" [routerLink]="['qr']" target="_blank"
      ><mat-icon
        aria-hidden="false"
        aria-label="Print Purchase QR"
        matTooltip="Print Purchase QR"
        fontIcon="qr_code"
      ></mat-icon></a
    ><a
      mat-button
      color="secondary"
      href="javascript:void(0)"
      (click)="shareShoutoutCreateLink(event)"
      ><mat-icon
        aria-hidden="false"
        aria-label="Share Purchase Link"
        matTooltip="Share Purchase Link"
        fontIcon="share"
      ></mat-icon></a
    ><a
      mat-button
      color="secondary"
      href="javascript:void(0)"
      [routerLink]="['edit']"
      ><mat-icon
        aria-hidden="false"
        aria-label="Edit Event"
        matTooltip="Edit Event"
        fontIcon="edit"
      ></mat-icon></a
    ><a
      mat-flat-button
      color="{{ shoutoutsToAnnounceCount > 0 ? 'accent' : 'primary' }}"
      (click)="startAnnouncing()"
      >Start Announcing&nbsp;<mat-icon
        aria-hidden="false"
        aria-label="Start Announcing"
        matTooltip="Start Announcing"
        fontIcon="announcement"
      ></mat-icon></a
  ></span>
</div>

<form
  #shoutoutsFilterForm
  class="shoutouts-filters"
  [formGroup]="filterForm"
  *ngIf="event && event.sessions && event.sessions.length > 0"
  (submit)="filterShoutouts()"
>
  <mat-form-field class="shoutouts-filters__input" appearance="outline">
    <mat-label for="sessionId">All sessions</mat-label>
    <mat-select
      formControlName="sessionId"
      (selectionChange)="filterShoutouts()"
    >
      <mat-option [value]="null">All sessions</mat-option>
      @for (session of event.sessions; track session.id) {
        <mat-option [value]="session.id">{{ session.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button
    mat-flat-button
    type="submit"
    color="primary"
    class="shoutouts-filters__button"
  >
    <mat-icon
      aria-hidden="false"
      aria-label="Filter Shoutouts"
      matTooltip="Filter Shoutouts"
      fontIcon="filter_list"
    ></mat-icon
    >&nbsp;Filter
  </button>
  <button
    mat-flat-button
    (click)="clearFilter()"
    color="secondary"
    class="shoutouts-filters__button"
  >
    <mat-icon
      aria-hidden="false"
      aria-label="Clear filter Shoutouts"
      matTooltip="Clear filter Shoutouts"
      fontIcon="clear_all"
    ></mat-icon
    >&nbsp;Clear
  </button>
</form>

<nav mat-tab-nav-bar [backgroundColor]="" [tabPanel]="tabPanel">
  <a
    mat-tab-link
    routerLinkActive
    #rla2="routerLinkActive"
    [active]="this.rla2.isActive"
    [routerLink]="['shoutouts', 'announce']"
    [queryParams]="{ sessionId }"
    ><span
      matBadge="{{
        shoutoutsToAnnounceCount > 9 ? '9+' : shoutoutsToAnnounceCount
      }}"
      matBadgeOverlap="false"
      [matBadgeColor]="shoutoutsToAnnounceCount > 0 ? 'warn' : 'primary'"
      >Announce</span
    ></a
  >
  <a
    mat-tab-link
    routerLinkActive
    #rla3="routerLinkActive"
    [active]="this.rla3.isActive"
    [routerLink]="['shoutouts', 'completed']"
    [queryParams]="{ sessionId }"
    >Completed</a
  >
  <a
    mat-tab-link
    routerLinkActive
    #rla4="routerLinkActive"
    [active]="this.rla4.isActive"
    [routerLink]="['shoutouts', 'rejected']"
    [queryParams]="{ sessionId }"
    >Rejected</a
  >
</nav>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

<router-outlet></router-outlet>
