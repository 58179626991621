// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyD5vCmgtWGfYz-OHZl9BN53Yp5cJCncz0s",
    authDomain: "the-shoutout-app-dev.firebaseapp.com",
    projectId: "the-shoutout-app-dev",
    storageBucket: "the-shoutout-app-dev.appspot.com",
    messagingSenderId: "934376128500",
    appId: "1:934376128500:web:150484dc8cd139094a43a1"
  },
  production: false,
  stripe: {
    publishableKey: 'pk_test_51LlfHkCFm4WXJRn9wUmEbXMu3enf4lXlbMgiUtzG1zSiUqSrpUX0EtIldvWHJyrWa0T7s7VUgSnsDYvpIJEXshqo00mNKx7YnV',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
