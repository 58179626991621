<div class="event-form__container">
  <mat-stepper orientation="vertical" [linear]="true" #stepper>
    <mat-step [stepControl]="eventForm">
      <form class="event-form" [formGroup]="eventForm">
        <ng-template matStepLabel>Event Details</ng-template>
        <mat-form-field class="event-form__input" appearance="fill">
          <mat-label for="name">Event Name</mat-label>
          <input matInput formControlName="name" type="text" />
          <div>
            <mat-error
              *ngIf="
                (name?.dirty || name?.touched) && name?.errors?.['required']
              "
              >Name is required.</mat-error
            >
            <mat-error
              *ngIf="
                (name?.dirty || name?.touched) && name?.errors?.['minlength']
              "
              >Minimum number of characters is
              {{ name?.errors?.["minlength"]?.["requiredLength"] }}.</mat-error
            >
            <mat-error
              *ngIf="
                (name?.dirty || name?.touched) && name?.errors?.['maxlength']
              "
              >Maximum number of characters is
              {{ name?.errors?.["maxlength"]?.["requiredLength"] }}.</mat-error
            >
          </div>
        </mat-form-field>
        <mat-form-field class="event-form__input" appearance="fill">
          <mat-label for="clientName">Gym Name</mat-label>
          <input matInput formControlName="clientName" type="text" />
          <div>
            <mat-error
              *ngIf="
                (clientName?.dirty || clientName?.touched) &&
                clientName?.errors?.['required']
              "
              >Client Name is required.</mat-error
            >
            <mat-error
              *ngIf="
                (clientName?.dirty || clientName?.touched) &&
                clientName?.errors?.['minlength']
              "
              >Minimum number of characters is
              {{
                clientName?.errors?.["minlength"]?.["requiredLength"]
              }}.</mat-error
            >
            <mat-error
              *ngIf="
                (clientName?.dirty || clientName?.touched) &&
                clientName?.errors?.['maxlength']
              "
              >Maximum number of characters is
              {{
                clientName?.errors?.["maxlength"]?.["requiredLength"]
              }}.</mat-error
            >
          </div>
        </mat-form-field>
        <mat-form-field class="event-form__input" appearance="fill">
          <mat-label>Dates</mat-label>
          <mat-date-range-input [rangePicker]="datePicker">
            <input
              matStartDate
              formControlName="startTime"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="endTime"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="datePicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #datePicker></mat-date-range-picker>
          @if (startTime?.hasError("matStartDateInvalid")) {
            <mat-error>Invalid start date</mat-error>
          }
          @if (endTime?.hasError("matEndDateInvalid")) {
            <mat-error>Invalid end date</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="event-form__input" appearance="fill">
          <mat-label for="streetAddress">Street Address</mat-label>
          <input matInput formControlName="streetAddress" type="text" />
          <div>
            <mat-error
              *ngIf="
                (streetAddress?.dirty || streetAddress?.touched) &&
                streetAddress?.errors?.['required']
              "
              >Street Address is required.</mat-error
            >
            <mat-error
              *ngIf="
                (streetAddress?.dirty || streetAddress?.touched) &&
                streetAddress?.errors?.['minlength']
              "
              >Minimum number of characters is
              {{
                streetAddress?.errors?.["minlength"]?.["requiredLength"]
              }}.</mat-error
            >
            <mat-error
              *ngIf="
                (streetAddress?.dirty || streetAddress?.touched) &&
                streetAddress?.errors?.['maxlength']
              "
              >Maximum number of characters is
              {{
                streetAddress?.errors?.["maxlength"]?.["requiredLength"]
              }}.</mat-error
            >
          </div>
        </mat-form-field>
        <mat-form-field class="event-form__input" appearance="fill">
          <mat-label for="city">City</mat-label>
          <input matInput formControlName="city" type="text" />
          <div>
            <mat-error
              *ngIf="
                (city?.dirty || city?.touched) && city?.errors?.['required']
              "
              >City is required.</mat-error
            >
            <mat-error
              *ngIf="
                (city?.dirty || city?.touched) && city?.errors?.['minlength']
              "
              >Minimum number of characters is
              {{ city?.errors?.["minlength"]?.["requiredLength"] }}.</mat-error
            >
            <mat-error
              *ngIf="
                (city?.dirty || city?.touched) && city?.errors?.['maxlength']
              "
              >Maximum number of characters is
              {{ city?.errors?.["maxlength"]?.["requiredLength"] }}.</mat-error
            >
          </div>
        </mat-form-field>
        <mat-form-field class="event-form__input" appearance="fill">
          <mat-label for="state">State</mat-label>
          <input matInput formControlName="state" type="text" />
          <div>
            <mat-error
              *ngIf="
                (state?.dirty || state?.touched) && state?.errors?.['required']
              "
              >State is required.</mat-error
            >
            <mat-error
              *ngIf="
                (state?.dirty || state?.touched) && state?.errors?.['minlength']
              "
              >Minimum number of characters is
              {{ state?.errors?.["minlength"]?.["requiredLength"] }}.</mat-error
            >
            <mat-error
              *ngIf="
                (state?.dirty || state?.touched) && state?.errors?.['maxlength']
              "
              >Maximum number of characters is
              {{ state?.errors?.["maxlength"]?.["requiredLength"] }}.</mat-error
            >
          </div>
        </mat-form-field>
        <mat-form-field class="event-form__input" appearance="fill">
          <mat-label for="pricing">Pricing</mat-label>
          <span matTextPrefix>$</span>
          <input matInput formControlName="pricing" type="number" />
          <div>
            <mat-error
              *ngIf="
                (pricing?.dirty || pricing?.touched) &&
                pricing?.errors?.['required']
              "
              >Pricing is required.</mat-error
            >
            <mat-error
              *ngIf="
                (pricing?.dirty || pricing?.touched) && pricing?.errors?.['min']
              "
              >Minimum pricing is ${{
                pricing?.errors?.["min"]?.["min"]
              }}.</mat-error
            >
            <mat-error
              *ngIf="
                (pricing?.dirty || pricing?.touched) && pricing?.errors?.['max']
              "
              >Maximum pricing is ${{
                pricing?.errors?.["max"]?.["max"]
              }}.</mat-error
            >
          </div>
        </mat-form-field>
        <div class="event-form__actions">
          <button
            class="event-form__submit"
            mat-raised-button
            color="primary"
            (click)="onSubmit(false, stepper)"
            [disabled]="eventForm.invalid"
          >
            Save & Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="eventForm">
      <form class="event-form" [formGroup]="eventForm">
        <ng-template matStepLabel>Add Sessions (Optional)</ng-template>
        <ng-container formArrayName="sessions">
          <ng-container
            *ngFor="let session of sessions.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="event-form__session">
              <mat-form-field class="event-form__input" appearance="fill">
                <mat-label for="name">Session Name</mat-label>
                <input
                  matInput
                  formControlName="name"
                  placeholder="Session {{ i + 1 }}"
                />
                <div>
                  <mat-error
                    *ngIf="
                      (name?.dirty || name?.touched) &&
                      name?.errors?.['required']
                    "
                    >Name is required.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      (name?.dirty || name?.touched) &&
                      name?.errors?.['minlength']
                    "
                    >Minimum number of characters is
                    {{
                      name?.errors?.["minlength"]?.["requiredLength"]
                    }}.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      (name?.dirty || name?.touched) &&
                      name?.errors?.['maxlength']
                    "
                    >Maximum number of characters is
                    {{
                      name?.errors?.["maxlength"]?.["requiredLength"]
                    }}.</mat-error
                  >
                </div>
              </mat-form-field>
              <button mat-fab (click)="removeSession(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
        </ng-container>
        <button
          class="event-form__submit"
          mat-raised-button
          color="secondary"
          (click)="addSession()"
        >
          <mat-icon>add</mat-icon>
          Add Session
        </button>
        <button
          class="event-form__submit"
          mat-raised-button
          color="primary"
          (click)="onSubmit(true, stepper)"
          [disabled]="eventForm.invalid"
        >
          Save
        </button>
      </form>
    </mat-step>
  </mat-stepper>
</div>
