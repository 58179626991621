import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  events$: Observable<Event[]>;
  user!: User;
  displayedColumns: string[] = [
    'event',
    'clientName',
    'schedule',
    'location',
    'pricing',
    'actions',
  ];
  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    public auth: AuthService,
  ) {
    this.route.data.subscribe(({ user }) => {
      this.user = user;
    });
    this.events$ = this.afs
      .collection<Event>('events', (ref) => {
        if (this.user.roles.admin) {
          return ref.orderBy('endTime', 'asc');
        } else {
          return ref
            .where('organizerIds', 'array-contains', this.user.uid)
            .orderBy('endTime', 'asc');
        }
      })
      .valueChanges({ idField: 'id' });
  }

  ngOnInit(): void {}
}
