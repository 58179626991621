<div class="container">
  <h1 class="title">Thank You for your Purchase</h1>
  <h2>Order Number:<br />{{ orderId }}</h2>
  <h3>
    We will announce your shoutout shortly.<br />In the meantime you can help
    your gym by:
  </h3>
  <a mat-button color="primary" href="javascript:void(0)" (click)="share()"
    >Share with a Friend</a
  >
  <a
    mat-button
    color="primary"
    [routerLink]="['/events', eventId, 'shoutouts', 'create']"
    >Sending Another Shoutout</a
  ><br />
</div>
