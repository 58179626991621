import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { Event } from '../../models/event.model';
import { Shoutout } from '../../models/shoutout.model';

@Component({
  selector: 'app-shoutouts',
  templateUrl: './shoutouts.component.html',
  styleUrls: ['./shoutouts.component.scss'],
})
export class ShoutoutsComponent implements OnInit {
  shoutouts$!: Observable<Shoutout[]>;
  event!: Event;
  user!: User;
  displayedColumns: string[] = [
    'sent time',
    'from',
    'to',
    'message',
    'actions',
  ];

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
  ) {
    this.route.parent?.data.subscribe(({ event, user }) => {
      this.user = user;
      this.event = event;
    });
    this.route.queryParams.subscribe((params) => {
      const sessionId = params['sessionId'] ? params['sessionId'] : null;
      this.route.data.subscribe(({ paid, moderated, approved, announced }) => {
        this.shoutouts$ = this.afs
          .collection<Shoutout>('shoutouts', (ref) => {
            if (sessionId) {
              return ref
                .where('eventId', '==', this.event.id)
                .where('paid', '==', paid)
                .where('moderated', '==', moderated)
                .where('approved', '==', approved)
                .where('announced', '==', announced)
                .where('sessionId', '==', sessionId)
                .orderBy('createdAt', 'asc');
            }
            return ref
              .where('eventId', '==', this.event.id)
              .where('paid', '==', paid)
              .where('moderated', '==', moderated)
              .where('approved', '==', approved)
              .where('announced', '==', announced)
              .orderBy('createdAt', 'asc');
          })
          .valueChanges({ idField: 'id' });
      });
    });
  }

  ngOnInit(): void {}

  canReject(shoutout: Shoutout): boolean {
    return (
      shoutout.paid &&
      !shoutout.moderated &&
      !shoutout.approved &&
      !shoutout.announced
    );
  }

  canRestore(shoutout: Shoutout): boolean {
    return (
      (shoutout.paid &&
        shoutout.moderated &&
        !shoutout.approved &&
        !shoutout.announced) ||
      (shoutout.paid &&
        shoutout.moderated &&
        shoutout.approved &&
        shoutout.announced)
    );
  }

  reject(shoutout: Shoutout) {
    this.afs.doc<Shoutout>(`shoutouts/${shoutout.id}`).update({
      moderated: true,
      approved: false,
    });
  }

  restore(shoutout: Shoutout) {
    this.afs.doc<Shoutout>(`shoutouts/${shoutout.id}`).update({
      moderated: false,
      approved: false,
      announced: false,
    });
  }
}
