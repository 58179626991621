import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Shoutout } from '../models/shoutout.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ShoutoutsResolverService {
  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private auth: AuthService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<(Shoutout | undefined)[]> | Promise<Shoutout[]> | Shoutout[] {
    const eventId = route.parent?.paramMap.get('eventId');
    let paid = route.data['paid'];
    let moderated = route.data['moderated'];
    let approved = route.data['approved'];
    let announced = route.data['announced'];

    return this.afs
      .collection<Shoutout>('shoutouts', (ref) =>
        ref
          .where('eventId', '==', eventId)
          .where('paid', '==', paid)
          .where('moderated', '==', moderated)
          .where('approved', '==', approved)
          .where('announced', '==', announced)
          .orderBy('createdAt', 'asc'),
      )
      .valueChanges({ idField: 'id' });
  }
}
