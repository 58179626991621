import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable, take, tap } from 'rxjs';
import { Role } from '../models/user.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let allowedRoles = route.data['allowedRoles'];

    return this.auth.user$.pipe(
      take(1),
      map((user) =>
        user && this.auth.checkRoleAuthorization(user, allowedRoles)
          ? true
          : false,
      ),
      tap((canView) => {
        if (!canView) {
          console.error('Access denied. Must have permission to view content');
          this.router.navigate(['/error', 401]);
        }
      }),
    );
  }
}
