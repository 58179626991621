<table mat-table [dataSource]="shoutouts$" class="mat-elevation-z1">
  <ng-container matColumnDef="sent time">
    <th mat-header-cell *matHeaderCellDef>Sent time</th>
    <td mat-cell *matCellDef="let shoutout"> {{ shoutout.createdAt.toDate() | date:'medium' }} </td>
  </ng-container>
  <ng-container matColumnDef="from">
    <th mat-header-cell *matHeaderCellDef>From</th>
    <td mat-cell *matCellDef="let shoutout"> {{shoutout.from}} </td>
  </ng-container>
  <ng-container matColumnDef="to">
    <th mat-header-cell *matHeaderCellDef>To</th>
    <td mat-cell *matCellDef="let shoutout"> {{shoutout.to}} </td>
  </ng-container>
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef>Message</th>
    <td mat-cell *matCellDef="let shoutout"> {{shoutout.message}} </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let shoutout">
      <span *ngIf="canRestore(shoutout)"><button mat-flat-button color="primary" (click)="restore(shoutout)">Restore</button></span>
      <span *ngIf="canReject(shoutout)"><button mat-flat-button color="primary" (click)="reject(shoutout)">Reject</button></span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
