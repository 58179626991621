<div class="forgot-password-form__container">
  <mat-card appearance="outlined" class="forgot-password-form__card">
    <mat-card-content>
      <form
        class="forgot-password-form"
        [formGroup]="forgotPasswordForm"
        (ngSubmit)="sendForgotPasswordEmail()"
        type=""
      >
        <mat-form-field class="forgot-password-form__input" appearance="fill">
          <mat-label>Email</mat-label>
          <input
            autocomplete="off"
            formControlName="email"
            type="email"
            matInput
            placeholder="Email"
          />
        </mat-form-field>
        <mat-error *ngIf="firebaseErrorMessage != ''">
          <span>{{ firebaseErrorMessage }}</span>
        </mat-error>
        <button
          class="forgot-password-form__submit"
          mat-raised-button
          color="primary"
        >
          Recover Password
        </button>
      </form>
    </mat-card-content>
  </mat-card>
  <div class="alt-actions">
    <button
      mat-button
      aria-label="Login"
      color="primary"
      [routerLink]="['/login']"
    >
      <mat-icon>login</mat-icon>Login
    </button>
  </div>
</div>
