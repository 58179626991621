<div class="body">
  <app-header></app-header>

  <div class="container">
    <router-outlet></router-outlet>
  </div>

  <mat-toolbar class="footer">
    <span class="header-spacer"></span>
    <span>Ultimate Shoutouts - Copyright {{ currentYear }}</span>
    <span class="header-spacer"></span>
  </mat-toolbar>
</div>
