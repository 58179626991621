import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Shoutout } from 'src/app/models/shoutout.model';

@Component({
  selector: 'app-announce',
  templateUrl: './announce.component.html',
  styleUrls: ['./announce.component.scss'],
})
export class AnnounceComponent implements OnInit {
  shoutout!: Shoutout;
  nothingToAnnounce: boolean = true;

  constructor(
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<AnnounceComponent>,
    @Inject(MAT_DIALOG_DATA) public shoutouts: Observable<Shoutout[]>,
  ) {
    shoutouts.subscribe((shoutouts) => {
      if (shoutouts.length > 0) {
        this.nothingToAnnounce = false;
        this.shoutout = shoutouts[0];
      } else {
        this.nothingToAnnounce = true;
      }
    });
  }

  ngOnInit(): void {}

  markAsAnnounced(shoutout: Shoutout) {
    this.afs.doc<Shoutout>(`shoutouts/${shoutout.id}`).update({
      moderated: true,
      approved: true,
      announced: true,
    });
  }

  reject(shoutout: Shoutout) {
    this.afs.doc<Shoutout>(`shoutouts/${shoutout.id}`).update({
      moderated: true,
      approved: false,
    });
  }
}
