<div class="events-header">
  <h1>Events</h1>
  <span class="events-header__spacer"></span>
  <span class="events-header__item">
    <button mat-flat-button color="primary" href="javascript:void(0)" [routerLink]="['create']" *ngIf="auth.canCreateEvents(user)">
      <mat-icon aria-hidden="false" aria-label="Create a new event" matTooltip="Create a new event" fontIcon="add"></mat-icon>
      Create Event
    </button>
  </span>
</div>
<div class="events-table">
  <table mat-table [dataSource]="events$" class="mat-elevation-z3">
    <ng-container matColumnDef="event">
      <th mat-header-cell *matHeaderCellDef>Event</th>
      <td mat-cell *matCellDef="let event"> <a mat-button color="primary" [routerLink]="[event.id]">{{event.name}}</a> </td>
    </ng-container>
    <ng-container matColumnDef="clientName">
      <th mat-header-cell *matHeaderCellDef>Gym Name</th>
      <td mat-cell *matCellDef="let event"> {{event.clientName}} </td>
    </ng-container>
    <ng-container matColumnDef="schedule">
      <th mat-header-cell *matHeaderCellDef>Schedule</th>
      <td mat-cell *matCellDef="let event"> {{ event.startTime | date }} - {{ event.endTime | date }} </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let event"> {{ event.streetAddress }}<br />{{ event.city }}, {{ event.state }}<br />{{ event.country }} </td>
    </ng-container>
    <ng-container matColumnDef="pricing">
      <th mat-header-cell *matHeaderCellDef>Pricing</th>
      <td mat-cell *matCellDef="let event"> ${{ event.pricing.toFixed(2) }} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let event">
        <button mat-button [matMenuTriggerFor]="eventMenu">...</button>
        <mat-menu #eventMenu="matMenu">
          <button mat-menu-item [routerLink]="[event.id, 'edit']">Edit</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>