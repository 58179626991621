import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { shareShoutout } from 'src/app/helpers/shareShoutoutLink';
import { AnnounceComponent } from '../announce/announce.component';

import { Event } from 'src/app/models/event.model';
import { Shoutout } from 'src/app/models/shoutout.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { EventCounter } from 'src/app/models/eventCounters.model';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  event$!: Observable<(Event & { id: string }) | undefined>;
  eventId!: string | null;
  user!: User;
  event!: Event;
  shoutoutsToAnnounce$!: Observable<Shoutout[]>;
  shoutoutsToAnnounceCount: number = 0;
  eventCounter$!: Observable<EventCounter | undefined>;
  filterForm: FormGroup;
  sessionId!: string | null;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    public dialog: MatDialog,
  ) {
    this.route.data.subscribe(({ event, user }) => {
      this.user = user;
      this.event = event;
      this.shoutoutsToAnnounce$ = this.afs
        .collection<Shoutout>('shoutouts', (ref) =>
          ref
            .where('eventId', '==', this.event.id)
            .where('paid', '==', true)
            .where('moderated', '==', false)
            .where('approved', '==', false)
            .where('announced', '==', false)
            .orderBy('createdAt', 'asc'),
        )
        .valueChanges({ idField: 'id' });
      this.shoutoutsToAnnounce$.subscribe(
        (shoutouts) => (this.shoutoutsToAnnounceCount = shoutouts.length),
      );

      this.eventCounter$ = this.afs
        .doc<EventCounter>(`eventCounters/${this.event.id}`)
        .valueChanges();
    });
    this.filterForm = new FormGroup({
      sessionId: new FormControl(null),
    });
    this.route.queryParams.subscribe((params) => {
      this.sessionId = params['sessionId'] || null;
      this.filterForm.setValue({
        sessionId: this.sessionId,
      });
    });
  }

  ngOnInit(): void {}

  shareShoutoutCreateLink(event: Event): void {
    shareShoutout(event);
  }

  startAnnouncing() {
    const dialogRef = this.dialog.open(AnnounceComponent, {
      width: '600px',
      data: this.shoutoutsToAnnounce$,
    });
  }

  filterShoutouts() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sessionId: this.filterForm.value.sessionId,
      },
      queryParamsHandling: 'merge',
    });
  }

  clearFilter() {
    this.filterForm.setValue({
      sessionId: null,
    });
    this.filterShoutouts();
  }
}
