import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable, interval, Subscription } from 'rxjs';
import { Shoutout } from 'src/app/models/shoutout.model';
import { Event } from 'src/app/models/event.model';
import { shareShoutoutLink } from 'src/app/helpers/shareShoutoutLink';

@Component({
  selector: 'app-event-wall',
  templateUrl: './event-wall.component.html',
  styleUrls: ['./event-wall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventWallComponent {
  event!: Event;
  shoutouts$!: Observable<Shoutout[]> | undefined;
  rotationIntervalSec: number = 15; // in seconds
  rotationIndex: number = 0;
  rotationLength: number = 0;
  qrWidth: number = 250;
  qrWidthRatio: number = 0.6;
  shoutoutRotationInterval$: Subscription;
  @ViewChild('shoutoutWall', { static: true }) shoutoutWall!: ElementRef;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {
    this.qrWidth = window.innerHeight * this.qrWidthRatio;
    this.route.data.subscribe(({ event }) => {
      this.event = event;
      this.getShoutouts();
    });
    this.shoutoutRotationInterval$ = interval(
      this.rotationIntervalSec * 1000,
    ).subscribe(this.rotateShoutouts.bind(this));
  }

  getShoutouts(sessionId: string | null = null) {
    this.shoutouts$ = this.afs
      .collection<Shoutout>('shoutouts', (ref) => {
        if (sessionId) {
          return ref
            .where('eventId', '==', this.event.id)
            .where('paid', '==', true)
            .where('moderated', '==', true)
            .where('approved', '==', true)
            .where('announced', '==', true)
            .where('sessionId', '==', sessionId)
            .orderBy('createdAt', 'asc');
        }
        return ref
          .where('eventId', '==', this.event.id)
          .where('paid', '==', true)
          .where('moderated', '==', true)
          .where('approved', '==', true)
          .where('announced', '==', true)
          .orderBy('createdAt', 'asc');
      })
      .valueChanges({ idField: 'id' });

    this.shoutouts$.subscribe((shoutouts) => {
      this.rotationIndex = 0;
      this.rotationLength = shoutouts.length;
    });
  }

  ngOnDestroy() {
    this.shoutoutRotationInterval$.unsubscribe();
  }

  rotateShoutouts() {
    this.rotationIndex++;
    console.log('rotating shoutouts', this.rotationIndex, this.rotationLength);
    if (this.rotationIndex === this.rotationLength) {
      this.qrWidth = window.innerHeight * this.qrWidthRatio;
      console.log('showing qr code', this.qrWidth);
    }
    if (this.rotationIndex > this.rotationLength) {
      this.rotationIndex = 0;
    }
    this.cd.markForCheck();
  }

  showFullscreen() {
    this.shoutoutWall.nativeElement.requestFullscreen();
  }

  shareLink(): string {
    return shareShoutoutLink(this.event?.id || null);
  }
}
