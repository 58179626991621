import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { Shoutout } from '../../models/shoutout.model';
import { Event } from '../../models/event.model';
import { loadStripe, Stripe, StripeError } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shoutout-create',
  templateUrl: './shoutout-create.component.html',
  styleUrls: ['./shoutout-create.component.scss'],
})
export class ShoutoutCreateComponent implements OnInit {
  eventId!: string | null;
  event$!: Observable<Event | undefined>;
  event!: Event;
  shoutoutForm!: UntypedFormGroup;
  shoutoutCollection: AngularFirestoreCollection<Shoutout>;
  confirmation: any;
  loading = false;
  stripeClientSecretFunc$!: any;
  stripeClientSecret!: string;
  stripe!: Stripe | null;
  stripeElements: any;
  paymentElement: any;
  stripePaymentFormComplete: boolean = false;
  stripePaymentMethod: string | null = null;
  stripeError!: StripeError;
  stripeProcessing: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private fb: UntypedFormBuilder,
  ) {
    this.shoutoutCollection = this.afs.collection<Shoutout>('shoutouts');
  }

  loadEvent = async (eventId: string | null) => {
    this.event$ = this.afs
      .doc<Event>(`events/${eventId}`)
      .valueChanges({ idField: 'id' });
    this.event = (await firstValueFrom(this.event$)) as Event;
  };

  initStripe = async () => {
    this.stripe = await loadStripe(environment.stripe.publishableKey);
    this.stripeClientSecretFunc$ = this.fns.httpsCallable('stripeClientSecret');
    this.stripeClientSecret = await firstValueFrom(
      this.stripeClientSecretFunc$({ eventId: this.eventId }),
    )
      .then((response: any) => {
        return response.clientSecret;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });

    this.stripeElements = this.stripe?.elements({
      clientSecret: this.stripeClientSecret,
    });

    this.paymentElement = this.stripeElements?.create('payment', {
      wallets: { applePay: 'auto', googlePay: 'auto' },
    });
    this.paymentElement?.mount('#stripe-payment-element');
    this.paymentElement?.on('change', this.stripeChangeHandler.bind(this));
  };

  ngOnInit(): void {
    this.initStripe();
    this.route.paramMap.subscribe((params) => {
      this.eventId = params.get('eventId');
      this.loadEvent(this.eventId);
      this.shoutoutForm = this.fb.group({
        eventId: this.eventId,
        to: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ],
        ],
        team: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ],
        ],
        from: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ],
        ],
        sessionId: null,
        message: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(255),
          ],
        ],
        paid: false,
        moderated: false,
        announced: false,
        approved: false,
      });
    });
  }

  get to() {
    return this.shoutoutForm.get('to');
  }
  get team() {
    return this.shoutoutForm.get('team');
  }
  get from() {
    return this.shoutoutForm.get('from');
  }
  get message() {
    return this.shoutoutForm.get('message');
  }
  get sessionId() {
    return this.shoutoutForm.get('sessionId');
  }

  stripeChangeHandler(event: any) {
    this.stripePaymentFormComplete = event.complete;
    this.stripePaymentMethod = event.value?.type;
  }

  onSubmit = async () => {
    this.stripeProcessing = true;
    this.stripe
      ?.confirmPayment({
        elements: this.stripeElements,
        redirect: 'if_required',
      })
      .then((result) => {
        this.stripeProcessing = false;
        if (result.error) {
          this.stripeError = result.error;
        } else {
          const shoutout = this.shoutoutForm.value as Shoutout;
          shoutout.paymentIntentId = result.paymentIntent?.id;
          shoutout.paymentMethod = result.paymentIntent?.payment_method;
          shoutout.price = this.event.pricing;
          shoutout.paid = true;
          this.shoutoutCollection.add(shoutout);
          this.router.navigate(
            ['events', this.eventId, 'shoutouts', 'confirmed'],
            { queryParams: { orderId: result.paymentIntent?.id } },
          );
        }
      });
  };
}
