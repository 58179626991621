<div class="signup-form__container">
  <mat-card appearance="outlined">
    <mat-card-content>
      <form class="signup-form" [formGroup]="signupForm" (ngSubmit)="signup()">
        <mat-form-field class="signup-form__input" appearance="fill">
          <mat-label>Full Name</mat-label>
          <input
            autocomplete="off"
            formControlName="displayName"
            type="text"
            matInput
            placeholder="Display Name"
          />
        </mat-form-field>

        <mat-form-field class="signup-form__input" appearance="fill">
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            type="email"
            matInput
            placeholder="Email"
          />
        </mat-form-field>

        <mat-form-field class="signup-form__input" appearance="fill">
          <mat-label>Password</mat-label>
          <input
            autocomplete="off"
            formControlName="password"
            type="password"
            matInput
            placeholder="Password"
          />
        </mat-form-field>
        <div *ngIf="firebaseErrorMessage != ''" class="error-style">
          <mat-error>{{ firebaseErrorMessage }}</mat-error>
        </div>
        <button class="signup-form__submit" mat-raised-button color="primary">
          Signup
        </button>
      </form>
    </mat-card-content>
  </mat-card>
  <button
    mat-button
    aria-label="Login"
    color="primary"
    [routerLink]="['/login']"
  >
    <mat-icon>login</mat-icon>Login
  </button>
</div>
