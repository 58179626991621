import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, LoginErrors } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  firebaseErrorMessage: string;
  user?: firebase.User;

  constructor(
    private authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private afAuth: AngularFireAuth,
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });

    this.firebaseErrorMessage = '';
  }

  ngOnInit(): void {}

  loginUser() {
    this.user = undefined;
    if (this.loginForm.invalid) return;

    this.authService
      .loginUser(this.loginForm.value.email, this.loginForm.value.password)
      .then((result) => {
        if (result == null) {
          this.router.navigate(['/events']);
        } else if (result.isValid == false) {
          switch (result.message) {
            case LoginErrors.INVALID_CREDENTIAL:
              this.firebaseErrorMessage = 'Invalid email or password.';
              break;
            case LoginErrors.EMAIL_NOT_VALIDATED:
              this.firebaseErrorMessage = LoginErrors.EMAIL_NOT_VALIDATED;
              this.user = result.user;
              break;
            default:
              this.firebaseErrorMessage = result.message;
              break;
          }
          console.error(result.message);
        }
      });
  }

  loginWithGoogle() {
    this.authService.GoogleAuth().then((result) => {
      if (result == null) {
        this.router.navigate(['/events']);
      } else if (result.isValid == false) {
        this.firebaseErrorMessage = result.message;
      }
    });
  }

  resendVerificationEmail() {
    if (this.user == undefined) return;
    this.user?.sendEmailVerification();
    this.user = undefined;
    this._snackBar.open('Verification email sent.', undefined, {
      duration: 5000,
    });
  }
}
