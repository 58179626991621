import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable, switchMap, take } from 'rxjs';
import { shareShoutoutLink } from 'src/app/helpers/shareShoutoutLink';
import { Event } from 'src/app/models/event.model';

@Component({
  selector: 'app-event-qr',
  templateUrl: './event-qr.component.html',
  styleUrls: ['./event-qr.component.scss'],
})
export class EventQrComponent implements OnInit {
  event$!: Observable<Event | undefined>;
  eventId!: string | null;
  qrWidth: number = 250;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.event$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.eventId = params.get('eventId');
        return this.afs
          .doc<Event>('events/' + this.eventId)
          .valueChanges({ idField: 'id' });
      }),
    );
    const tempQrWidth = window.innerWidth * 0.9;
    this.qrWidth = tempQrWidth > 600 ? 600 : tempQrWidth;
  }

  shareLink(): string {
    return shareShoutoutLink(this.eventId);
  }

  printQRCode(): void {
    print();
  }
}
