<div class="button-container">
  <mat-form-field class="shoutouts-filters__input" appearance="outline">
    <mat-label for="sessionId">All sessions</mat-label>
    <mat-select (selectionChange)="getShoutouts($event.value)">
      <mat-option [value]="null">All sessions</mat-option>
      @for (session of event.sessions; track session.id) {
        <mat-option [value]="session.id">{{ session.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button
    mat-flat-button
    color="primary"
    aria-label="Show Fullscreen"
    class="show_fullscreen"
    (click)="showFullscreen()"
  >
    Start in Fullscreen
  </button>
</div>

<div class="background" #shoutoutWall>
  <ng-container *ngFor="let shoutout of shoutouts$ | async; let i = index">
    <div class="fade-in shoutout">
      <ng-container *ngIf="i == rotationIndex">
        <div class="to">
          <span class="to-athlete">{{ shoutout.to }}</span
          ><span *ngIf="shoutout.team !== ''"> from {{ shoutout.team }}</span>
        </div>
        <div class="message">
          <span class="quote quote--left">&ldquo;</span>{{ shoutout.message
          }}<span class="quote quote--right">&rdquo;</span>
        </div>
        <div class="from">{{ shoutout.from }}</div>
      </ng-container>
    </div>
  </ng-container>
  <div class="qr-code" *ngIf="rotationIndex == (shoutouts$ | async)?.length">
    <qrcode
      [qrdata]="shareLink()"
      [width]="qrWidth"
      [errorCorrectionLevel]="'M'"
    >
    </qrcode>
  </div>
  <img src="/assets/logo.png" class="logo" />
  <a mat-button href="ultimateshoutouts.com" class="branding"
    >ultimateshoutouts.com</a
  >
</div>
