import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider, AuthProvider } from 'firebase/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { User, Role } from '../models/user.model';
import { Event } from '../models/event.model';

interface Signup {
  displayName: string;
  email: string;
  password: string;
}

export enum LoginErrors {
  INVALID_CREDENTIAL = 'Firebase: The supplied auth credential is incorrect, malformed or has expired. (auth/invalid-credential).',
  EMAIL_NOT_VALIDATED = 'Email has not been validated.',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$!: Observable<User | null | undefined>;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private afa: AngularFireAuth,
  ) {
    this.user$ = this.afa.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      }),
    );
  }

  signupUser(signupUser: Signup): Promise<any> {
    return this.afa
      .createUserWithEmailAndPassword(signupUser.email, signupUser.password)
      .then((result) => {
        this.registerNewUser(result.user, signupUser.displayName);
      })
      .catch((err) => {
        return { isValid: false, message: err.message };
      });
  }

  registerNewUser(fbUser: any, displayName?: string) {
    const user: User = {
      uid: fbUser?.uid,
      displayName: displayName || fbUser.displayName,
      email: fbUser?.email,
      roles: {
        organizer: true,
      },
    };
    this.updateUserData(user);
    fbUser?.sendEmailVerification();
  }

  loginUser(email: string, password: string): Promise<any> {
    return this.afa
      .signInWithEmailAndPassword(email, password)
      .then((authCredentials) => {
        if (authCredentials.user?.emailVerified) {
          return null;
        } else {
          return {
            isValid: false,
            message: LoginErrors.EMAIL_NOT_VALIDATED,
            user: authCredentials.user,
          };
        }
      })
      .catch((err) => {
        return { isValid: false, message: err.message };
      });
  }

  logout() {
    this.afa.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  private updateUserData(user: User) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `users/${user.uid}`,
    );
    return userRef.set(user, { merge: true });
  }

  ///// Role-based Authorization //////

  isAdmin(user: User): boolean {
    return user.roles?.admin || false;
  }

  isOrganizer(user: User): boolean {
    return user.roles?.admin || false;
  }

  isAnnouncer(user: User): boolean {
    return user.roles?.admin || false;
  }

  // Events
  // View - Admin, organizers(partial), Announcer(partial)
  canViewEvents(user: User) {
    const allowed = ['admin', 'organizer', 'announcer'];
    return this.checkRoleAuthorization(user, allowed);
  }
  // Create - Admin, organizers(partial)
  canCreateEvents(user: User) {
    const allowed = ['admin', 'organizer'];
    return this.checkRoleAuthorization(user, allowed);
  }
  // Edit - Admin, organizers(partial)
  canEditEvents(user: User, event: Event): boolean {
    const allowed = ['admin', 'organizer'];
    return (
      this.checkRoleAuthorization(user, allowed) &&
      this.checkEventAssignment(user, event)
    );
  }
  // Delete - Nobody
  canDeleteEvents(user: User, event: Event) {
    return false;
  }

  // Shoutouts
  // View - Admin, organizers(partial), Announcer(partial)
  canViewShoutouts(user: User, event: Event) {
    const allowed = ['admin', 'organizer', 'announcer'];
    return (
      this.checkRoleAuthorization(user, allowed) &&
      this.checkEventAssignment(user, event)
    );
  }
  // Create - Open
  canCreateShoutouts(user: User, event: Event) {
    return true;
  }
  // Edit - Nobody
  canEditShoutouts(user: User, event: Event) {
    return false;
  }
  // Delete - Nobody
  canDeleteShoutouts(user: User, event: Event) {
    return false;
  }

  // determines if user has matching role
  public checkRoleAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false;
    if (user.roles.admin) return true;
    for (const role of allowedRoles) {
      if (user.roles[role as keyof Role]) {
        return true;
      }
    }
    return false;
  }

  private checkEventAssignment(user: User, event: Event): boolean {
    if (user.roles.admin) {
      return true;
    }
    if (user.uid && event?.organizerIds?.includes(user.uid)) {
      return true;
    } else if (user.uid && event?.announcerIds?.includes(user.uid)) {
      return true;
    }
    return false;
  }

  ///// OAuth Providers /////

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider: AuthProvider) {
    return this.afa
      .signInWithPopup(provider)
      .then((result) => {
        if (result.additionalUserInfo?.isNewUser) {
          this.registerNewUser(result.user);
        }
        this.router.navigate(['/events']);
      })
      .catch((err) => {
        return { isValid: false, message: err.message };
      });
  }
}
