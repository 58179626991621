import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom, Observable, take, tap } from 'rxjs';
import { Event } from '../models/event.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EventResolverService {
  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private auth: AuthService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Event | undefined> | Promise<Event> | Event {
    const hasRoles =
      Object.keys(route.data).includes('allowedRoles') &&
      route.data['allowedRoles'].length > 0;
    return this.afs
      .doc<Event>('events/' + route.paramMap.get('eventId'))
      .valueChanges({ idField: 'id' })
      .pipe(
        tap((event) => {
          firstValueFrom(this.auth.user$).then((user) => {
            if (!event?.name) {
              // Since we are requesting the idField event will never be null.
              this.router.navigate(['/error/404']);
            }
            if (
              hasRoles &&
              !(user && event && this.auth.canEditEvents(user, event))
            ) {
              this.router.navigate(['/error/403']);
            }
          });
        }),
      );
  }
}
