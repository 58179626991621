import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  hideMenu: boolean = false;
  isMobile: boolean = false;

  constructor(
    protected auth: AuthService,
    protected responsive: BreakpointObserver,
    private cd: ChangeDetectorRef,
  ) {
    this.hideMenu = window.location.pathname.endsWith('shoutouts/create');
  }

  ngOnInit(): void {
    this.responsive
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;
        if (
          breakpoints[Breakpoints.HandsetPortrait] ||
          breakpoints[Breakpoints.HandsetLandscape]
        ) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
        this.cd.detectChanges();
      });
  }

  logout(): void {
    this.auth.logout();
  }
}
