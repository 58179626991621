export const shareShoutout = (event: any): void => {
  // This should be an Event Model
  navigator.share({
    url: shareShoutoutLink(event?.id),
    text: `Give a shoutout to your favorite gymnast at this ${event?.name} (${event?.clientName})\n`,
    title: `${event?.name} (${event?.clientName} Shoutout`,
  });
};

export const shareShoutoutLink = (eventId: string | null): string => {
  // This should be an Event Model
  return `${window.location.protocol}//${window.location.host}/events/${eventId}/shoutouts/create`;
};
