import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm!: FormGroup;
  firebaseErrorMessage: string;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.firebaseErrorMessage = '';
  }

  ngOnInit(): void {
    this.signupForm = new FormGroup({
      displayName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  signup(): void {
    if (this.signupForm.invalid) {
      return;
    }

    this.authService
      .signupUser(this.signupForm.value)
      .then((result: any) => {
        if (result == null) {
          this.router.navigate(['events']);
        } else if (result.isValid == false) {
          this.firebaseErrorMessage = result.message
            .replace('Firebase: ', '')
            .replace(' (auth/email-already-in-use).', '');
        }
      })
      .catch((err: Error) => {
        console.error(err.message);
      });
  }
}
