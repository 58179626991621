import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  firebaseErrorMessage: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private afAuth: AngularFireAuth,
  ) {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    this.firebaseErrorMessage = '';
  }

  sendForgotPasswordEmail() {
    if (this.forgotPasswordForm.get('email')?.invalid) return;

    this.afAuth
      .sendPasswordResetEmail(this.forgotPasswordForm.value.email)
      .then((result: any) => {
        if (result == null) {
          this.router.navigate(['/login']);
        } else if (result.isValid === false) {
          this.firebaseErrorMessage = result.message;
        }
      });
  }
}
