<h1 *ngIf="event" class="shoutout-form__header">
  {{ event.name }} ({{ event.clientName }})
</h1>
<div class="shoutout-form__container">
  <mat-card appearance="outlined">
    <mat-card-content>
      <form
        class="shoutout-form"
        [formGroup]="shoutoutForm"
        #shoutoutFormElement="ngForm"
        (ngSubmit)="onSubmit()"
      >
        <mat-form-field
          class="shoutout-form__input"
          appearance="fill"
          *ngIf="event && event.sessions.length > 0"
        >
          <mat-label for="sessionId">Select you gymnast session</mat-label>
          <mat-select formControlName="sessionId">
            @for (session of event.sessions; track session.id) {
              <mat-option [value]="session.id">{{ session.name }}</mat-option>
            }
          </mat-select>
          <div>
            <mat-error>Please doublecheck your gymnast session.</mat-error>
          </div>
        </mat-form-field>
        <mat-form-field class="shoutout-form__input" appearance="fill">
          <mat-label for="to">To</mat-label>
          <input matInput formControlName="to" />
          <mat-error
            *ngIf="(to?.dirty || to?.touched) && to?.errors?.['required']"
            >To is required.</mat-error
          >
          <div *ngIf="(to?.dirty || to?.touched) && to?.errors?.['required']">
            To is required.
          </div>
          <mat-error
            *ngIf="(to?.dirty || to?.touched) && to?.errors?.['minlength']"
            >Minimum number of characters is
            {{ to?.errors?.["minlength"]?.["requiredLength"] }}.</mat-error
          >
          <mat-error
            *ngIf="(to?.dirty || to?.touched) && to?.errors?.['maxlength']"
            >Maximum number of characters is
            {{ to?.errors?.["maxlength"]?.["requiredLength"] }}.</mat-error
          >
        </mat-form-field>
        <mat-form-field class="shoutout-form__input" appearance="fill">
          <mat-label for="team">Team</mat-label>
          <input matInput formControlName="team" />
          <mat-error
            *ngIf="(team?.dirty || team?.touched) && team?.errors?.['required']"
            >To is required.</mat-error
          >
          <div
            *ngIf="(team?.dirty || team?.touched) && team?.errors?.['required']"
          >
            To is required.
          </div>
          <mat-error
            *ngIf="
              (team?.dirty || team?.touched) && team?.errors?.['minlength']
            "
            >Minimum number of characters is
            {{ team?.errors?.["minlength"]?.["requiredLength"] }}.</mat-error
          >
          <mat-error
            *ngIf="
              (team?.dirty || team?.touched) && team?.errors?.['maxlength']
            "
            >Maximum number of characters is
            {{ team?.errors?.["maxlength"]?.["requiredLength"] }}.</mat-error
          >
        </mat-form-field>
        <mat-form-field class="shoutout-form__input" appearance="fill">
          <mat-label for="from">From</mat-label>
          <input matInput formControlName="from" />
          <mat-error
            *ngIf="(from?.dirty || from?.touched) && from?.errors?.['required']"
            >From is required.</mat-error
          >
          <mat-error
            *ngIf="
              (from?.dirty || from?.touched) && from?.errors?.['minlength']
            "
            >Minimum number of characters is
            {{ from?.errors?.["minlength"]?.["requiredLength"] }}.</mat-error
          >
          <mat-error
            *ngIf="
              (from?.dirty || from?.touched) && from?.errors?.['maxlength']
            "
            >Maximum number of characters is
            {{ from?.errors?.["maxlength"]?.["requiredLength"] }}.</mat-error
          >
        </mat-form-field>
        <mat-form-field class="shoutout-form__input" appearance="fill">
          <mat-label for="message">Message</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            formControlName="message"
          ></textarea>
          <mat-error
            *ngIf="
              (message?.dirty || message?.touched) &&
              message?.errors?.['required']
            "
            >Message is required.</mat-error
          >
          <mat-error
            *ngIf="
              (message?.dirty || message?.touched) &&
              message?.errors?.['minlength']
            "
            >Minimum number of characters is
            {{ message?.errors?.["minlength"]?.["requiredLength"] }}.</mat-error
          >
          <mat-error
            *ngIf="
              (message?.dirty || message?.touched) &&
              message?.errors?.['maxlength']
            "
            >Maximum number of characters is
            {{ message?.errors?.["maxlength"]?.["requiredLength"] }}.</mat-error
          >
        </mat-form-field>
        <div id="stripe-payment-element">
          <mat-spinner class="stripe-spinner"></mat-spinner>
        </div>
        <mat-error
          *ngIf="
            (stripeError || shoutoutForm.invalid) &&
            shoutoutFormElement.submitted
          "
        >
          <strong>There is something wrong with your message</strong>
          <div *ngIf="stripeError">
            {{ stripeError.message }}
          </div>
        </mat-error>
        <div>
          <button
            mat-flat-button
            color="primary"
            class="shoutout-form__submit"
            type="submit"
            [disabled]="
              shoutoutForm.invalid ||
              !stripePaymentFormComplete ||
              !stripePaymentMethod ||
              stripeProcessing
            "
          >
            Purchase ${{ event ? event.pricing.toFixed(2) : "-" }}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
