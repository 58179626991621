<div mat-dialog-content class="announce-done" *ngIf="nothingToAnnounce">
  <h1 mat-dialog-title>You are all caught up!</h1>
  <div class="announce-done_buttons">
    <button
      mat-flat-button
      color="primary"
      class="announce-done__button"
      aria-label="close"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</div>
<div mat-dialog-content class="announce" *ngIf="shoutout && !nothingToAnnounce">
  <div class="announce__close">
    <div class="announce__spacer"></div>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-label>From</mat-label>
  <h2>
    {{ shoutout.from }}
  </h2>

  <mat-label>To</mat-label>
  <h2>
    {{ shoutout.to }}
  </h2>

  <mat-label>Team</mat-label>
  <h2>
    {{ shoutout.team }}
  </h2>

  <h1 class="announce__message">"{{ shoutout.message }}"</h1>

  <mat-dialog-actions>
    <div class="announce__buttons">
      <button
        mat-button
        aria-label="Reject"
        class="announce__button"
        (click)="reject(shoutout)"
      >
        Reject
      </button>
      <button
        mat-flat-button
        color="primary"
        class="announce__button"
        aria-label="Announced"
        (click)="markAsAnnounced(shoutout)"
      >
        Announced
      </button>
    </div>
  </mat-dialog-actions>
</div>
